<template>
  <div class="container">
    <GeneralTable :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      tableName="ActionLog"
    >
      <template slot="operate"
        slot-scope="{item}">
        <div class="operate">
          <el-button class="btn"
            type="text"
            @click="handleDetail(item)">查看详情</el-button>
          <el-button type="text"
            @click="handleDelSingle(item)">删除</el-button>
        </div>
      </template>
    </GeneralTable>

    <el-dialog title="详情"
      :visible.sync="dialogVisible"
      width="600px"
      custom-class="dialog">
      <div class="dialog-header"
        slot="title">详情</div>
      <div class="content">
        <div class="label">
          <div class="header">标题</div>
          <div class="label-item">id</div>
          <div class="label-item">admin_id</div>
          <div class="label-item">用户名</div>
          <div class="label-item">url</div>
          <div class="label-item">标题</div>
          <div class="label-item">内容</div>
          <div class="label-item">ip</div>
          <div class="label-item">useragent</div>
          <div class="label-item">创建时间</div>
        </div>
        <div class="detail">
          <div class="header">内容</div>
          <div class="detail-item">{{curInfo.id}}</div>
          <div class="detail-item">{{curInfo.tenantId}}</div>
          <div class="detail-item">{{curInfo.userName}}</div>
          <div class="detail-item">{{curInfo.url}}</div>
          <div class="detail-item">{{curInfo.module}}</div>
          <div class="detail-item">{{curInfo.content}}</div>
          <div class="detail-item">{{curInfo.operateIp}}</div>
          <div class="detail-item">{{curInfo.userAgent}}</div>
          <div class="detail-item">{{curInfo.operateTime}}</div>
        </div>
      </div>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { getOperateLog, operateLogDel, exportOperateLog, operateLogDelBatch } from '@/api/SystemData.js';
export default {
  components: { GeneralTable },
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      curInfo: {},
      totalNum: 0,
      column: [{
        label: '序号',
        type: 'index',
        isShow: true,
        fixed: 'left'
      },
      {
        label: "操作用户",
        prop: "userName",
        isShow: true,
      },
      {
        label: "操作机器IP",
        prop: "operateIp",
        isShow: true,
      },
      {
        label: "操作模块",
        prop: "module",
        isShow: true,
      },
      {
        label: "操作内容",
        prop: "content",
        isShow: true,
      },
      {
        label: "操作时间",
        prop: "operateTime",
        isShow: true,
      },
      {
        label: "操作",
        prop: "operate",
        isShow: true,
        width: 200,
        fixed: 'right',
        isSlot: true,
      }
      ],
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: false, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [{
          label: '操作用户',
          prop: 'userName',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入操作用户"
        },
        {
          label: '操作模块',
          prop: 'module',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入操作模块"
        }, {
          label: '操作ip',
          prop: 'operateIp',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入操作ip"
        }, {
          label: '日期',
          formType: 'daterange',
          clearable: true,
            format: 'yyyy.MM.dd',
          prop: ['beginTime', 'endTime']
        }]
      },
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    getList(data) {
      getOperateLog(data).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleSearch(data) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      // if (data.keyword) {
      //   data.userName = data.keyword;
      //   data.operateIp = data.keyword;
      //   data.content = data.keyword;
      //   data.module = data.keyword;
      // }
      if (data.beginTime && data.endTime) {
        data.beginTime += ' 00:00:00';
        data.endTime += ' 00:00:00';
      }
      delete data.pageSize;
      delete data.pageNum;
      delete data.keyword;
      this.getList(data);
    },
    handleDelSingle(data) {
      this.$confirm('确认删除该日志?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        operateLogDel(data.id).then(res => {
          if (res) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
          }
          this.$refs.GeneralTable.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm('确认删除日志?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = '';
          data.forEach(element => {
            ids += element.id + ',';
          });
          ids = ids.slice(0, ids.length - 1);
          operateLogDelBatch(ids).then(res => {
            if (res) {
              this.$message({
                message: res.msg,
                type: 'success'
              });
            }
            this.$refs.GeneralTable.getList();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的日志！');
      }
    },
    handleDetail(data) {
      this.curInfo = data;
      this.dialogVisible = true;
    },
    handleExport(data) {
      let ids = [];
      data.forEach(element => {
        ids.push(element.id);
      });
      exportOperateLog({ ids: ids.join(',') }).then(res => {
        if (res) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  .operate {
    .btn {
      margin-right: 10px;
    }
  }
  .dialog {
    .dialog-header {
      background-color: #2c3e50;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      padding-left: 20px;
      color: #fff;
    }
    .content {
      display: flex;
      width: 100%;
      .label,
      .detail {
        .header {
          font-weight: bold;
        }
        div {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }
          height: 24px;
          line-height: 24px;
        }
      }
      .label {
        div {
          width: 88px;
        }
      }
      .detail {
        width: 472px;
        .detail-item {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
  position: relative;
  .el-dialog__headerbtn {
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__close {
      color: #fff;
      font-size: 20px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
</style>
